







































import Vue from "vue";
import Requester from "@/requester/index";
import { TokenFormData } from "../../../../back/src/types/formData";
export default Vue.extend({
  name: "UsersActivate",

  data() {
    return {
      erro: "",
    };
  },
  mounted() {
    this.ativaCadastro({ token: this.$route.query.token });
  },
  methods: {
    async ativaCadastro(token: TokenFormData) {
      try {
        await Requester.users.public.activeUser(token);
      } catch (err) {
        this.erro = err.response?.data ?? err.toString();
      }
    },
  },
});
